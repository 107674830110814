*
{
  margin: 0;
  padding: 0;
}

.App
{
  display: flex;
  justify-content: center;
  height: 100%;
background-color: #8f8f8f;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

}
.heading
{
  font-family: 'Playfair Display', serif;
  font-size: 2em;
  margin-bottom: 10%;
  color: rgb(52, 30, 65);
}
.container
{
  
  margin-top: 3%;
  width: 30vw;
  display: flex;
  height: 100vh;
    padding: 20px;
  flex-direction: column;
 align-items: center;
 font-family: 'Playfair Display', serif;

}

.todoForm{
  display: flex;
  justify-content: space-around;
  width: 450px;
}

input{
  width:70%;
  padding: 6px;
  border: none;
background-color: #fcfcfe;
color: black;}

input:focus
{
  outline: none;
}

button{
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  margin: 2px 5px;
  background-color: rgb(52, 30, 65);
  color: #bdbaba;
}

.alltodos
{
  list-style: none;
  margin: 0;
  padding: 0;
}

.singletodo {
  margin: 15px 0;
  background-color:#fcfcfe; /* 0.8 is the alpha value for transparency */
  padding: 3px 35px;
  display: flex;
  width: 352px;
  align-items: center;
  color: black;
  opacity: 2;
  border: 2px solid black;
}

.todotext
{
  flex:1;
}